
@use '@angular/material' as mat;
@import '@angular/cdk/overlay-prebuilt.css';
@include mat.core();

$black-87-opacity: rgba(0, 0, 0, 0.87);

$pst-blue-palette: (
  10: #ECEDF0,
  25: #D0D4D9, 
  50: #A2A8B4, 
  75: #747D8F,
  100: #455269,
  200: #3E4B61,
  300: #374259,
  400: #303951,
  500: #2A3248,
  600: #232B40,
  700: #1C2337,
  800: #151B2E,
  900: #0E1324, 
  A100: #D6DAE0,
  A200: #BCC1CC,
  A400: #8C94A6, 
  A700: #61687D,
  contrast: (
    10: $black-87-opacity,
    25: $black-87-opacity,
    50: $black-87-opacity,
    75: white,
    100: white,
    200: white,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: $black-87-opacity,
    A200: $black-87-opacity,
    A400: $black-87-opacity,
    A700: $black-87-opacity,
  )
);

$pst-orange-palette: (
  10: #FDF1E9, 
  25: #FBDDC9, 
  50: #F7BB92, 
  75: #F3995C, 
  100: #EF7726,
  200: #E56C22, 
  300: #DB611F,
  400: #D0571B,
  500: #C54C18,
  600: #AA4015, 
  700: #8F3511, 
  800: #74290D, 
  900: #5A1F09, 
  A100: #FFE1C9, 
  A200: #FFC099, 
  A400: #FF8040, 
  A700: #FF571A, 
  contrast: (
    10: $black-87-opacity,  
    25: $black-87-opacity,
    50: $black-87-opacity,
    75: white,
    100: white,
    200: white,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: $black-87-opacity, // Acentos claros usan negro.
    A200: $black-87-opacity,
    A400: white,             // Acentos más oscuros usan blanco.
    A700: white,
  )
);



$PreSupplyTour-primary: mat.define-palette($pst-blue-palette, 100, 100, 50, 400);
$PreSupplyTour-accent: mat.define-palette($pst-orange-palette, 100, 100, 50, 400);

$PreSupplyTour-warn: mat.define-palette(mat.$red-palette);

$PreSupplyTour-theme: mat.define-light-theme((
  color: (
    primary: $PreSupplyTour-primary,
    accent: $PreSupplyTour-accent,
    warn: $PreSupplyTour-warn,
  ),
  typography: mat.define-typography-config(),
  density: 0
));

@include mat.all-component-themes($PreSupplyTour-theme);

@tailwind base;
@tailwind components;
@tailwind utilities;

:root .lpx-theme-dark {
  --lpx-logo: url('/assets/images/logo/logo-light.svg');
  --lpx-logo-icon: url('/assets/images/logo/icon.svg');
}

:root .lpx-theme-light {
  --lpx-logo: url('/assets/images/logo/logo-dark.svg');
  --lpx-logo-icon: url('/assets/images/logo/icon.svg');
}
:root{
  --lpx-theme-light-bg: url('/assets/images/login/login-bg-img-light.svg');
  --lpx-theme-dim-bg: url('/assets/images/login/login-bg-img-dim.svg');
  --lpx-theme-dark-bg: url('/assets/images/login/login-bg-img-dark.svg');
  --lpx-info: #455269;
  --lpx-border-color: transparent;
  --lpx-content-bg: #ECEDF0;
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.card-shadow {
  box-shadow: 0px 4px 4px 0px #45526933;
}

// Titles
h1, h2 ,h3 {
  @apply text-black whitespace-pre;
}

h1 {
  @apply font-semibold text-lg md:text-xl 2xl:text-2xl;
}

h2 {
  @apply font-medium text-base md:text-lg 2xl:text-xl;
}

h3 {
  @apply font-normal text-sm md:text-base 2xl:text-lg;
}

.mat-tab-label {
  @apply font-medium text-base md:text-lg 2xl:text-xl;
}

.medium-text-size {
  @apply text-[12px] md:text-base 2xl:text-lg
}

.small-text-size {
  @apply text-[10px] md:text-sm 2xl:text-base
}

.tiny-text-size {
  @apply text-[10px] md:text-xs 2xl:text-sm
}

.medium-icon-size {
  @apply md:w-[20px] md:h-[20px] md:text-[20px] 2xl:w-[24px] 2xl:h-[24px] 2xl:text-[24px] #{!important};
}